export default function authHeader(){
  let user = window.localStorage.getItem('user');

  if(!user){
    return;
  }

  const parsedUser = JSON.parse(user);

  if(parsedUser && parsedUser.accessToken) {
    return { Authorization: `Bearer ${parsedUser.accessToken}`}
  } else {
    return {};
  }
}
